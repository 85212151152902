import React, { useState } from 'react';
import parseDate from '../utils/parseDate';
import { graphql } from "gatsby"
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import HeaderNew from '../components/layout/HeaderLayout';
import "../theme/layout.scss"
import ModalSuscribe from '../components/layout/ModalSuscribre';


const Estrategia = ({data }) =>{

  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }    

    const estrategiaDeInversion = data.estrategiaDeInversion.edges

    // console.log(estrategiaDeInversion)


    return (
      <>
          <HeaderNew openModal={openSuscribe} ticker={false} mb={true}/>
          <div className='wrap-content'>
            <h2>Estrategia de Inversión</h2>
            {estrategiaDeInversion && estrategiaDeInversion.map(({ node }) => {
                const { titulo, resumen, slug, createdAt, autor} = node
                    return (
                        
                        <div className="item">
                            <h2 className="title"><b>{titulo}</b></h2>
                            <p className="resume-content">{resumen}</p>
                            <p className="date">{autor && autor?.nombre}</p>
                            <p className="date">{parseDate(createdAt)}</p>
                            <a href={`/estrategia-de-inversion/${slug}`} >
                                <div className="link">Ver más</div>
                            </a>
                        </div>
                    )
               
            })}
          </div>
          <CustomFooter openModal={openSuscribe}/>
          <GdprCookies />
          {
            open && <ModalSuscribe closeModal={closeSuscribe}/>
          }
      </>    
    )
}

export default Estrategia

export const pageQuery = graphql`
query MyQueryEstrategia{
    
    estrategiaDeInversion: allContentfulEstrategiaDeInversion(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
                nombre
            }
          }
        }
      }
      

  }
`;
